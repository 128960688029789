import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import P5a from "../imgs/p5-a";
import P5b from "../imgs/p5-b";
import P5c from "../imgs/p5-c";
import P5d from "../imgs/p5-d";
import P5e from "../imgs/p5-e";


const P5Images = () => {
	return (
		<Carousel 
			autoPlay={false} 
			showArrows={false} 
			showStatus={false} 
			showThumbs={false}
			dynamicHeight
		>
			<P5a/>
			<P5b/>
			<P5c/>
			<P5d/>
			<P5e/>
		</Carousel>
	);
};

export default P5Images;