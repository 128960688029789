import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import P3a from "../imgs/p3-a";
import P3b from "../imgs/p3-b";
import P3c from "../imgs/p3-c";
import P3d from "../imgs/p3-d";
import P3sequance from "../imgs/p3-sequance";


const P3Images = () => {
	return (
		<Carousel 
			autoPlay={false} 
			showArrows={false} 
			showStatus={false} 
			showThumbs={false}
			dynamicHeight
		>
			<P3a/>
			<P3b/>
			<P3c/>
			<P3d/>
			<P3sequance/>
		</Carousel>
	);
};

export default P3Images;