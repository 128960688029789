import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import P4a from "../imgs/p4-a";
import P4b from "../imgs/p4-b";



const P4Images = () => {
	return (
		<Carousel 
			autoPlay={false} 
			showArrows={false} 
			showStatus={false} 
			showThumbs={false}
			dynamicHeight
		>
			<P4a/>
			<P4b/>
		</Carousel>
	);
};

export default P4Images;