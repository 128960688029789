import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import P6a from "../imgs/p6-a";
import P6b from "../imgs/p6-b";
import P6c from "../imgs/p6-c";
import P6d from "../imgs/p6-d";
import P6e from "../imgs/p6-e";


const P6Images = () => {
	return (
		<Carousel 
			autoPlay={false} 
			showArrows={false} 
			showStatus={false} 
			showThumbs={false}
			dynamicHeight
		>
			<P6a/>
			<P6b/>
			<P6c/>
			<P6d/>
			<P6e/>
		</Carousel>
	);
};

export default P6Images;