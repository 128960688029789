import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import P1a from "../imgs/p1-a";
import P1b from "../imgs/p1-b";
import P1c from "../imgs/p1-c";
import P1d from "../imgs/p1-d";
import P1e from "../imgs/p1-e";
import P1f from "../imgs/p1-f";
import P1sequance from "../imgs/p1-sequance";

const P1Images = () => {
	return (
		<Carousel 
			autoPlay={false} 
			showArrows={false} 
			showStatus={false} 
			showThumbs={false}
			dynamicHeight
		>
			<P1a/>
			<P1b/>
			<P1c/>
			<P1d/>
			<P1e/>
			<P1f/>
			<P1sequance/>
		</Carousel>
	);
};

export default P1Images;