import React from 'react';
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import carouselStyles from '../carouselStyles.css';
//
import P1Images from './P1Images';
import P2Images from './P2Images';
import P3Images from './P3Images';
import P4Images from './P4Images';
import P5Images from './P5Images';
import P6Images from './P6Images';

const ItemContainer = styled.div`
	display:flex;
	flex-direction:column;
	background-color:#000;
	width:100%;
	height:100%;
	overflow:hidden;
	padding:25px 20px;
	border:solid 1px yellow;
`;

const CarouselWrapper = styled.div`
	display:block;
	max-width:100%;
	height:100%;
	display:flex;
	justify-content:center;
	.carousel-root{
		display:block;
		width:100%;
		height:100%;
	}
`;


const getProjectImages = (pid) => {
	switch(pid){
		case "p1":
			return (<P1Images />);
		case "p2":
			return (<P2Images />);
		case "p3":
			return (<P3Images />);
		case "p4":
			return (<P4Images />);
		case "p5":
			return (<P5Images />);
		case "p6":
			return (<P6Images />);
		default:
			return (<div>default</div>);
	}
	;
}
const ProjectItem = ({ opened, pid }) => {
	if(!pid){
		return (<ItemContainer  />)
	}

	const items = getProjectImages(pid)

	return (
		<ItemContainer  >
			{
				opened && (
					<CarouselWrapper className={opened ? 'opened' : ''}>
						{items}
					</CarouselWrapper>
				)
			}
		</ItemContainer>
	);
};

export default ProjectItem;