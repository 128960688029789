import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const StyledImg = styled(Img)`
	width:100%;
`;
const P6c = () => {
  const data = useStaticQuery(graphql` 
    query {
      placeholderImage: file(relativePath: { eq: "p6/boxas-p6-c.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <StyledImg loading="eager" fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default P6c
