import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import P2a from "../imgs/p2-a";
import P2b from "../imgs/p2-b";
import P2c from "../imgs/p2-c";
import P2d from "../imgs/p2-d";


const P2Images = () => {
	return (
		<Carousel 
			autoPlay={false} 
			showArrows={false} 
			showStatus={false} 
			showThumbs={false}
			dynamicHeight
		>
			<P2a/>
			<P2b/>
			<P2c/>
			<P2d/>
		</Carousel>
	);
};

export default P2Images;