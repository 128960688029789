import React, { Component } from 'react';
import styled from "styled-components"
import BorderedImg from "../imgs/BorderedImg";
import Project from "./Project";

const Container = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	width:100%;
`;

const Item = styled.div`
	display:block;
	margin:20px;
	width:290px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:310px;
	}
`;

class PortfolioGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			count: 0,
			isProjectOpen:false,
			currLeftOffset:0,
			currTopOffset:0,
			currHeight:0,
			currWidth:0,
			pid:null,
		};
	}

	componentDidMount() {
    this.int = setInterval(() => {
			this.setState({
				count:this.state.count + 1
			}, () => {
				if(this.state.count >= 12){
					clearInterval(this.int);
				}
			})
		}, 180);
  }
	componentWillUnmount() {
    clearInterval(this.int);
	}

	onImageSelected(ref,pid) {
		this.setState({
			currLeftOffset:ref.current.offsetLeft,
			currTopOffset:ref.current.offsetTop,
			currWidth:ref.current.offsetWidth,
			currHeight:ref.current.offsetHeight,
			isProjectOpen:true,
			pid
		})
	}

	toggleHandler() {
		this.setState({
			isProjectOpen:!this.state.isProjectOpen
		})
	}

	render(){
		const status = this.state.count - 5;
		const ref1 = React.createRef();
		const ref2 = React.createRef();
		const ref3 = React.createRef();
		const ref4 = React.createRef();
		const ref5 = React.createRef();
		const ref6 = React.createRef();
		return (
			<React.Fragment>
				<Container>
					<Item>
						<BorderedImg 
							ref={ref1} 
							pid="p1" 
							onSelected={this.onImageSelected.bind(this)} 
							interact 
							size={status > 0 ? "full" : "shrink"} 
						/>
					</Item>
					<Item>
						<BorderedImg 
							ref={ref2} 
							pid="p2" 
							onSelected={this.onImageSelected.bind(this)} 
							interact 
							size={status > 1 ? "full" : "shrink"} 
						/>
					</Item>
					<Item>
						<BorderedImg 
							ref={ref3} 
							pid="p3" 
							onSelected={this.onImageSelected.bind(this)} 
							interact 
							size={status > 2 ? "full" : "shrink"} 
						/>
					</Item>
					<Item>
						<BorderedImg 
							ref={ref4} 
							pid="p4" 
							onSelected={this.onImageSelected.bind(this)} 
							interact 
							size={status > 3 ? "full" : "shrink"} 
						/>
					</Item>
					<Item>
						<BorderedImg 
							ref={ref5} 
							pid="p5" 
							onSelected={this.onImageSelected.bind(this)} 
							interact 
							size={status > 4 ? "full" : "shrink"} 
						/>
					</Item>
					<Item>
						<BorderedImg 
							ref={ref6} 
							pid="p6" 
							onSelected={this.onImageSelected.bind(this)} 
							interact 
							size={status > 5 ? "full" : "shrink"} 
						/>
					</Item>
				</Container>
				<Project 
					opened={this.state.isProjectOpen}
					leftOffset={this.state.currLeftOffset}
					topOffset={this.state.currTopOffset}
					itemHeight={this.state.currHeight}
					itemWidth={this.state.currWidth}
					toggleHandler={this.toggleHandler.bind(this)}
					pid={this.state.pid}
				/>
			</React.Fragment>
			
		);
	}
}



export default PortfolioGrid;