import React, { useState, useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import ProjectItem from './ProjectItem';

const Container = styled.div`

`;

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height:100vh;
	background-color: rgba(0,0,0,0.85);
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease-out;
	&.opened{
		opacity: 1;
		pointer-events: all;
	}
`;
const Content = styled.div`
	display: flex;
  flex-direction:column;
	position: fixed;
	top: 0;
	left:0%;
	width: 100%;
	height:100vh;
	/* background-color: ${props => props.theme.black}; */
	z-index: 100;
	pointer-events: none;
	&.opened{
		right:0;
		pointer-events: all;
	}
`;
const Box = styled.div`
	display:block;
	position:absolute;
	z-index: 101;
	left: ${props => props.leftOffset + 'px'};
	top: ${props => props.topOffset + 'px'};
	width: ${props => props.itemWidth + 'px'};
	height:${props => props.itemHeight + 'px'};
	opacity:0;
	pointer-events: none;
	&.opened{
		opacity:1;
		pointer-events: all;
	}
	&.expand{
		left: 1%;
		top: 1%;
		width: 98%;
		height:98vh;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 550ms;
		transition-property: top, left, width, height;
	}
`;

const CloseContainer = styled.div`
	position:absolute;
	top:10%;
	right:5%;
	z-index: 102;
	opacity:0;
	pointer-events: none;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 550ms;
	transition-property: right, opacity;
	&.opened{
		opacity:1;
		right:8%;
		pointer-events: all;
		transition-delay: 500ms;
	}
`;

const CloseBtn = styled.button`
	margin:0 1rem;
	display:flex;
	justify-content:center;
	align-items:center;
	text-decoration:none;
	color:${props => props.theme.red};
	padding:0.5rem 1rem;
	font-weight:700;
	background-color:${props => props.theme.black};
	border:solid 1px ${props => props.theme.red};
	text-transform:uppercase;
	transition:${props => props.theme.trans};
	cursor: pointer;
	&:hover, &:focus{
		color:${props => props.theme.black};
		background-color:${props => props.theme.red};
		border-color:${props => props.theme.red};
		.icon{
			fill:${props => props.theme.black};
		}
	}
	@media only screen and (max-width: 875px) {
	}
`;

const SVGIcon = styled.svg`
	fill:${props => props.theme.red};
	width:12px;
	margin-left:1rem;
	pointer-events:none;
	transition:${props => props.theme.trans};
`;
const BorderBase = styled.div`
	display:block;
	background-color:${props => props.theme.red};
	min-height:3px;
	min-width:3px;
	position:absolute;
	z-index:10;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 550ms;
	transition-property: top, left, bottom, right, width, height;
`;
const BorderTop = styled(BorderBase)`
	top:7%;
	width:99%;
	margin-left:1%;
`
const BorderRight = styled(BorderBase)`
	right:7%;
	height:95%;
`
const BorderBottom = styled(BorderBase)`
	bottom:7%;
	width:97%;
	margin-left:1%;
`
const BorderLeft = styled(BorderBase)`
	left:7%;
	height:100%;
`

const BorderImgMask = styled.div`
	display:block;
	background-color:#000;
	position:absolute;
	z-index:5;
	width:86%;
	height:86%;
	left:7%;
	top:7%;
	overflow:hidden;
	opacity:0;
	pointer-events: none;
	transition: opacity 0.5s ease-in;
	&.opened{
		opacity:1;
		pointer-events: all;
	}
`;
const Project = ({
	opened, 
	leftOffset, 
	topOffset, 
	itemHeight, 
	itemWidth, 
	toggleHandler,
	pid
}) => {

	//
	const [classState, setClassState] = useState("small");
	const [yScroll, setYScroll] = useState(0);
	const [xScroll, setXScroll] = useState(0);
	//
	useEffect(() => {
		if(opened){
			setClassState("small");
			setTimeout(() => {
				setClassState("expand");
			}, 15);
		} else {
			setClassState("small");
		}
		if (typeof window !== `undefined`) {
			setXScroll(window.scrollX);
			setYScroll(window.scrollY);
		}
	}, [opened]);

	const y = topOffset - yScroll;
	const x = leftOffset - xScroll;
	return (
		<Container>
				<Backdrop className={opened ? 'opened' : ''} />
				<Content className={opened ? 'opened' : ''}>
					<CloseContainer 
						className={opened ? 'opened' : ''}
						onClick={() => toggleHandler()}
					>
						<CloseBtn>
							Close
							<SVGIcon className="icon" viewBox="0 0 20 20">
								<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/>
							</SVGIcon>
						</CloseBtn>
					</CloseContainer>

						<Box 
							leftOffset={x} 
							topOffset={y}
							itemWidth={itemWidth}
							itemHeight={itemHeight} 
							className={`
								${opened ? 'opened' : ''} 
								${classState}
							`}
						>
							<BorderTop />
							<BorderRight />
							<BorderBottom />
							<BorderLeft />
							<BorderImgMask className={opened ? 'opened' : ''} >
								<ProjectItem opened={opened} pid={pid} />
							</BorderImgMask>
						</Box>
					
				</Content>
		</Container>
	);
};

export default Project;